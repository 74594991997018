import React from "react"
import parse, { domToReact } from "html-react-parser"

export const parseRawHtml = (rawHtml) => {
  return parse(rawHtml, {
    replace: ({ attribs, children }) => {
      if (!attribs || !attribs.href) return
      if (/^https?.*$/.test(attribs.href)) {
        return (
          <a target="_blank" {...attribs}
            children={domToReact(children)}
          />
        )
      }
    }
  })
}
