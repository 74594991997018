import _ from "lodash"
import React from "react"

import {
  Dropdown,
  Icon,
} from "semantic-ui-react"
import "semantic-ui-css/semantic.css"
import {
  EmailShareButton, EmailIcon,
  FacebookShareButton, FacebookIcon,
  LinkedinShareButton, LinkedinIcon,
  TwitterShareButton, TwitterIcon,
} from "react-share"


export default (props) => {

  return (
    <Dropdown
      closeOnBlur
      closeOnChange
      closeOnEscape
      direction="left"
      floating
      icon="share alternate square"
      inline
      children={
        <Dropdown.Menu
          children={
            <>
              <Dropdown.Item content={
                <EmailShareButton
                  subject={props.config.email.subject}
                  body={props.config.email.body}
                  url={props.config.email.url}
                  children={
                    <>
                      <EmailIcon size="20" style={{ marginRight: "7px", marginBottom: "-3px" }} />
                      <span>Email</span>
                    </>
                  }
                />
              } />
              <Dropdown.Divider style={{ margin: "0em" }} />
              <Dropdown.Item content={
                <FacebookShareButton
                  url={props.config.facebook.url}
                  quote={props.config.facebook.quote}
                  hashtag={props.config.facebook.hashtag}
                  children={
                    <>
                      <FacebookIcon size="20" style={{ marginRight: "7px", marginBottom: "-3px" }} />
                      <span>Facebook</span>
                    </>
                  }
                />
              } />
              <Dropdown.Divider style={{ margin: "0em" }} />
              <Dropdown.Item content={
                <LinkedinShareButton
                  url={props.config.linkedin.url}
                  title={props.config.linkedin.title}
                  summary={props.config.linkedin.summary}
                  source={props.config.linkedin.source}
                  children={
                    <>
                      <LinkedinIcon size="20" style={{ marginRight: "7px", marginBottom: "-3px" }} />
                      <span>LinkedIn</span>
                    </>
                  }
                />
              } />
              <Dropdown.Divider style={{ margin: "0em" }} />
              <Dropdown.Item content={
                <TwitterShareButton
                  url={props.config.twitter.url}
                  title={props.config.twitter.title}
                  via={props.config.twitter.via}
                  hashtags={props.config.twitter.hashtags}
                  children={
                    <>
                      <TwitterIcon size="20" style={{ marginRight: "7px", marginBottom: "-3px" }} />
                      <span>Twitter</span>
                    </>
                  }
                />
              } />
            </>
          }
        />
      }
      style={{
        float: "right",
        fontSize: "1.1em",
      }}
    />
  )

}
