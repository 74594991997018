import _ from "lodash"
import React from "react"
import { Helmet } from "react-helmet"
import { useMediaQuery } from "react-responsive"

import {
  Button,
  Container,
  Dropdown,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Search,
  Segment,
  TransitionablePortal,
  Visibility,
} from "semantic-ui-react"
import "semantic-ui-css/semantic.css"
import "./responsive-layout.css"

import AlgoliaSearch from "./algolia-search"
import MailchimpBox from "./mailchimp-box"


export default (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    minWidth: Responsive.onlyTablet.maxWidth
  })
  const isTabletOrMobile = useMediaQuery({
    maxWidth: Responsive.onlyTablet.maxWidth
  })
  return (
    <>
      {
        isDesktopOrLaptop &&
        <Layout {...props} />
      }
      {
        isTabletOrMobile &&
        <LayoutMobile {...props} />
      }
    </>
  )
}

const Layout = (props) => (
  <>
    <Helmet>
      {props.title && <title>{props.title}</title>}
      <meta property="og:title" content="Advancing Equal Rights In The World's Constitutions" />
      <meta name="description" content="Advancing Equal Rights In The World's Constitutions" />
      <meta property="og:description" content="Advancing Equal Rights In The World's Constitutions" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://constitutionsmatter.org/" />
      <meta property="og:site_name" content="Advancing Equal Rights In The World's Constitutions" />
      <meta name="twitter:description" content="Advancing Equal Rights In The World's Constitutions" />
      <meta name="twitter:title" content="Advancing Equal Rights In The World's Constitutions" />
      <meta name="twitter:image" content="https://constitutionsmatter.org/media/Logo-01.jpg" />
      <meta property="og:image:secure_url" content="https://constitutionsmatter.org/media/Logo-01.jpg" />
      <meta property="og:image" content="https://constitutionsmatter.org/media/Logo-01.jpg" />
      <meta name="twitter:card" content="https://constitutionsmatter.org/media/Logo-01.jpg" />
    </Helmet>
    <MastHead />
    <NavBar />
    {props.children}
    <Footer />
  </>
)

const LayoutMobile = (props) => (
  <>
    <Helmet title={props.title} />
    <MastHeadMobile />
    <NavBarMobile />
    {
      React.Children.map(props.children, (child) => (
        React.cloneElement(child, { isMobile: true })
      ))
    }
    <FooterMobile />
  </>
)

const MastHead = () => (
  <Container style={{ marginTop: "2em", marginBottom: "2em", }}>
    <Grid
      children={
        <>
          <Grid.Column width="10"
            children={
              <Header size="large">
                <Image spaced as="a" href="/" style={{
                  backgroundImage: "url(/media/Logo-01.jpg)",
                  backgroundSize: "150%",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  width: "4em",
                  height: "2em",
                }} />
                <Header.Content as="a" href="/" style={{ color: "unset", }}>
                  Advancing Equal Rights In The World's Constitutions
                  <Header.Subheader
                    style={{
                      marginTop: "0.5em"
                    }}
                    content="Resources for Making Change"
                  />
                </Header.Content>
              </Header>
            }
          />
          <Grid.Column width="6" floated="right"
            children={
              <AlgoliaSearch />
            }
          />
        </>
      }
    />
  </Container>
)

const MastHeadMobile = () => (
  <Container fluid style={{ marginTop: "2em", marginBottom: "2em", }}>
    <Header size="medium" textAlign="center">
      <Image centered as="a" href="/" style={{
        backgroundImage: "url(/media/Logo-01.jpg)",
        backgroundSize: "150%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "4em",
        height: "2em",
        display: "block",
      }} />
      <Header.Content as="a" href="/" style={{
        color: "unset",
        padding: "0em",
        marginTop: "1em",
      }}>
        Advancing Equal Rights In The World's Constitutions
        <Header.Subheader
          style={{
            marginTop: "0.5em"
          }}
          content="Resources for Making Change"
        />
      </Header.Content>
    </Header>
  </Container>
)

class NavBar extends React.Component {

  state = {
    isMenuFixed: false
  }

  render() {
    return (
      <Container fluid>
        <Visibility
          once={false}
          onTopVisible={() => this.setState({ isMenuFixed: false })}
          onTopPassed={() => this.setState({ isMenuFixed: true })}
        >
          <Menu
            inverted size="large" color="black"
            fixed={this.state.isMenuFixed ? "top" : null}
            style={this.state.isMenuFixed ? fixedMenuStyle : menuStyle}
          >
            <Grid container centered stretched>
              <Grid.Column textAlign="center" style={{ width: "9%", paddingLeft: "0em", paddingRight: "0em", }}>
                <Menu.Item as="a" href="/about"
                  style={{
                    justifyContent: "center",
                  }}
                  content="About"
                />
              </Grid.Column>
              <Grid.Column style={{ width: "19%", paddingLeft: "0em", paddingRight: "0em", }}>
                <Dropdown item simple
                  as="a" href="/how-can-constitutions-advance-equality"
                  text="How Can Constitutions Advance Equality?"
                  style={{ lineHeight: "1.5em" }}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item as="a"
                      href="/how-can-constitutions-advance-equality#why-constitutions"
                      text="Why Constitutions?"
                    />
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a"
                      href="/how-can-constitutions-advance-equality#constitutions-questions-and-answers"
                      text="Constitutions: Questions and Answers"
                    />
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a"
                      href="/how-can-constitutions-advance-equality#constitutional-cases-from-around-the-world"
                      text="Constitutional Cases from Around the World"
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
              <Grid.Column style={{ width: "19%", paddingLeft: "0em", paddingRight: "0em", }}>
                <Dropdown item simple
                  as="a" href="/how-does-your-constitution-compare"
                  text="How Does Your Constitution Compare?"
                  style={{ lineHeight: "1.5em" }}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item as="a" href="/how-does-your-constitution-compare#maps-and-figures">Maps and Figures</Dropdown.Item>
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a" href="/how-does-your-constitution-compare#briefs-and-fact-sheets">Briefs and Fact Sheets</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
              <Grid.Column style={{ width: "19%", paddingLeft: "0em", paddingRight: "0em", }}>
                <Dropdown item simple
                  as="a" href="/making-and-changing-constitutions"
                  text="Making and Changing Constitutions"
                  style={{ lineHeight: "1.5em" }}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item as="a" href="/making-and-changing-constitutions#global-trends-over-time">Global Trends Over Time</Dropdown.Item>
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a" href="/making-and-changing-constitutions#guides-and-resources">Guides and Resources</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
              <Grid.Column style={{ width: "15%", paddingLeft: "0em", paddingRight: "0em", }}>
                <Dropdown item simple
                  as="a" href="/monitoring-and-accountability"
                  text="Monitoring and Accountability"
                  style={{ lineHeight: "1.5em" }}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item as="a" href="/monitoring-and-accountability#guides-and-resources">Guides and Resources</Dropdown.Item>
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a" href="/monitoring-and-accountability#data-for-making-the-case">Data for Making the Case</Dropdown.Item>
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a" href="/monitoring-and-accountability#citizens-play-an-important-role">Citizens Play An Important Role</Dropdown.Item>
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a" href="/monitoring-and-accountability#initiatives-and-organizations-of-network-members">Initiatives and Organizations of Network Members</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
              <Grid.Column style={{ width: "19%", paddingLeft: "0em", paddingRight: "0em", }}>
                <Dropdown item simple
                  as="a" href="/teaching-and-learning"
                  text="Teaching and Learning&nbsp;&nbsp;&nbsp;"
                  style={{ lineHeight: "1.5em" }}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item as="a" href="/teaching-and-learning#books">Books</Dropdown.Item>
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a" href="/teaching-and-learning#classroom-guides-and-resources">Classroom Guides and Resources</Dropdown.Item>
                    <Dropdown.Divider style={{ margin: "0em" }} />
                    <Dropdown.Item as="a" href="/teaching-and-learning#trainers-guides-and-resources">Trainers' Guides and Resources</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
            </Grid>
          </Menu>
        </Visibility>
      </Container>
    )
  }

}

class NavBarMobile extends React.Component {

  state = {
    showNavMenu: false
  }

  render() {
    return (
      <TransitionablePortal
        openOnTriggerClick
        onOpen={() => this.setState({ showNavMenu: true })}
        closeOnTriggerClick
        onClose={() => this.setState({ showNavMenu: false })}
        trigger={
          <Button circular size="big"
            icon={
              <Icon
                name={this.state.showNavMenu ? "close" : "bars"}
                className="navbar-mobile-button-icon"
              />
            }
            color={this.state.showNavMenu ? "white" : "black"}
            style={{
              position: "fixed",
              top: "1em",
              right: "1em",
              zIndex: "2"
            }}
          />
        }
        children={
          <Menu fluid inverted vertical size="huge"
            style={{
              position: "fixed",
              top: "0em",
              margin: "0em",
            }}
            children={
              <>
                <Menu.Item as="a" href="/about">About</Menu.Item>
                <Menu.Item as="a" href="/how-can-constitutions-advance-equality">How Can Constitutions Advance Equality?</Menu.Item>
                <Menu.Item as="a" href="/how-does-your-constitution-compare">How Does Your Constitution Compare?</Menu.Item>
                <Menu.Item as="a" href="/making-and-changing-constitutions">Making and Changing Constitutions</Menu.Item>
                <Menu.Item as="a" href="/monitoring-and-accountability">Monitoring and Accountability</Menu.Item>
                <Menu.Item as="a" href="/teaching-and-learning">Teaching and Learning</Menu.Item>
              </>
            }
          />
        }
      />
    )
  }

}

const Footer = () => (
  <Segment inverted vertical
    style={{
      marginTop: "5em",
      paddingTop: "3em",
      paddingBottom: "3em",
      textAlign: "center"
    }}
    children={
      <Grid inverted divided >
        <Grid.Column width={8}>
          <Image centered fluid as="a" href="/" style={{
            backgroundImage: "url(/media/Logo-01.jpg)",
            backgroundSize: "150%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            width: "4em",
            height: "3em",
          }} />
          <List horizontal inverted divided link size="small">
            <List.Item as="a" target="_blank" href="mailto:info@constitutionsmatter.org">
              Contact Us
            </List.Item>
            <List.Item as="a" target="_blank" href="https://app.termly.io/document/privacy-policy/46e0f12f-f45e-4aac-a01d-00fd70da1b5e">
              Privacy Policy
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={8} style={{ paddingLeft: "7em" }}>
          <MailchimpBox />
        </Grid.Column>
      </Grid>
    }
  />
)

const FooterMobile = () => (
  <Segment inverted vertical
    style={{
      marginTop: "5em",
      paddingTop: "3em",
      paddingBottom: "3em",
      textAlign: "center"
    }}
    children={
      <>
        <Image centered fluid as="a" href="/" style={{
          backgroundImage: "url(/media/Logo-01.jpg)",
          backgroundSize: "150%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "4em",
          height: "3em",
        }} />
        <List horizontal inverted divided link size="small">
          <List.Item as="a" target="_blank" href="mailto:info@constitutionsmatter.org">
            Contact Us
          </List.Item>
          <List.Item as="a" target="_blank" href="https://app.termly.io/document/privacy-policy/46e0f12f-f45e-4aac-a01d-00fd70da1b5e">
            Privacy Policy
          </List.Item>
        </List>
      </>
    }
  />
)

const menuStyle = {
  border: "unset",
  borderRadius: "unset",
  boxShadow: "unset",
  marginBottom: "unset",
  marginTop: "2em",
  transition: "unset"
}

const fixedMenuStyle = {
  border: "unset",
  borderRadius: "unset",
  boxShadow: "unset",
  marginBottom: "unset",
  marginTop: "unset",
  transition: "unset"
}
