import _, { isNull } from "lodash"
import React from "react"
import algoliasearch from "algoliasearch/lite"

import { Search, Label } from 'semantic-ui-react'

const algoliaSearchClient = algoliasearch("3RYLE0NWFZ", "ec2bb813abe0f299b6ffb2c783002865")

export default class AlgoliaSearch extends React.Component {

  state = {
    isLoading: false,
    results: [],
    value: ''
  }

  render() {
    return (
      <Search size="big" fluid className="srch" placeholder="Search linked resources..."
        loading={this.state.isLoading}
        // onResultSelect={(e, { result }) => this.setState({ value: result.title })}
        onSearchChange={_.debounce((e, { value }) => {
          this.setState({ isLoading: true, value })
          setTimeout(() => {
            if (this.state.value.length < 1) {
              return this.setState({
                isLoading: false,
                results: [],
                value: ''
              })
            }
            return algoliaSearchClient.multipleQueries([
              {
                indexName: "briefs_and_fact_sheets",
                query: this.state.value,
              },
              {
                indexName: "cases_and_case_resources",
                query: this.state.value,
              },
              {
                indexName: "maps_and_figures",
                query: this.state.value,
              },
              {
                indexName: "pages_and_sections",
                query: this.state.value,
              },
              {
                indexName: "trends_figures_and_tables",
                query: this.state.value,
              },
            ]).then(({ results }) => {
              this.setState({
                isLoading: false,
                results: results.reduce(
                  (items, r) => items.concat(
                    r.hits.map(item => ({
                      ...item,
                      index: r.index,
                      prefix: prefixMap[r.index]
                    }))
                  ),
                  []
                ).slice(0, 10).filter(
                  hit => hit.title && hit.url
                ).concat({
                  title: "View all results",
                  url: `/search?q=${this.state.value}`,
                  index: -1,
                  target: "_self"
                }),
              })
            })
          }, 300)
        }, 500, {
          leading: true,
        })}
        results={this.state.results}
        value={this.state.value}
        resultRenderer={hit =>
          <Label basic as="a" target={hit.target || "_blank"}
            icon={hit.url.match(/\.pdf$/) ? "file pdf outline" : "external square"}
            href={hit.url}
            style={{ fontSize: "0.75em", border: "none", padding: "0.7em", fontWeight: "unset" }}
            content={hit.prefix ? `[${hit.prefix}] ${hit.title}` : hit.title}
          />
        }
      />
    )
  }
}

const prefixMap = {
  "briefs_and_fact_sheets": "brief",
  "cases_and_case_resources": "case",
  "maps_and_figures": "map",
  "trends_figures_and_tables": "trend",
}