import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

import { Button, Form } from "semantic-ui-react"

export default () => {

  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState("")
  
  const onSubmit = async (e) => {
    e.preventDefault()
    await addToMailchimp(email)
    setSuccess(true)
    setEmail("")
  }

  return (
    <Form onSubmit={onSubmit} style={{ textAlign: "left" }}>
      <h3>Sign up to receive updates</h3>
      <Form.Group>
        <Form.Input disabled={success} width="8" name="email" value={email} placeholder="Email Address" onChange={(e) => {setEmail(e.currentTarget.value)}} />
        <Button disabled={success} type="submit" color={success ? "green" : ""} style={{ marginLeft: "2em" }}>{success ? "Subscription Requested!" : "Subscribe!"}</Button>
      </Form.Group>
    </Form>
  )

}


// export default () => {

//   const [success, setSuccess] = useState(false)
//   const [email, setEmail] = useState("")
//   const [frequency, setFrequency] = useState("")

//   const onSubmit = async (e) => {
//     e.preventDefault()
//     await addToMailchimp(email, { frequency })
//     setSuccess(true)
//     setEmail("")
//     setFrequency("")
//   }

//   return (
//     <Form style={{ textAlign: "left" }}>
//       <h3>Sign up to receive updates</h3>
//       <Form.Group>
//         <Form.Input disabled={success} width="8" name="email" value={email}
//           placeholder="Email Address"
//           onChange={(e) => { setEmail(e.currentTarget.value) }}
//         />
//         <Modal
//           closeIcon
//           trigger={
//             <Button disabled={success} color={success ? "green" : ""}
//               content={success ? "Subscribed!" : "Subscribe!"} style={{ marginLeft: "2em" }}
//             />
//           }
//           children={
//             <>
//               <Modal.Header>Sign up to receive updates</Modal.Header>
//               <Modal.Content image>
//                 <Image wrapped size="medium" src="/media/Logo-01.jpg" />
//                 <Segment basic floated="right">
//                   <Form onSubmit={onSubmit}>
//                     <Form.Input disabled={success} name="email" value={email}
//                       placeholder="Email Address" onChange={(e) => setEmail(e.currentTarget.value)}
//                     />
//                     <Form.Group disabled={success} inline style={{ marginTop: "2em" }}>
//                       <label>Frequency</label>
//                       <Form.Radio
//                         label='Weekly'
//                         value='weekly'
//                         checked={frequency === 'weekly'}
//                         onChange={(e, { value }) => setFrequency(value)}
//                       />
//                       <Form.Radio
//                         label='Monthly'
//                         value='monthly'
//                         checked={frequency === 'monthly'}
//                         onChange={(e, { value }) => setFrequency(value)}
//                       />
//                     </Form.Group>
//                     <Button type="submit" color={success ? "green" : ""} style={{ marginTop: "2em" }}>
//                       {success ? "Subscribed!" : "Subscribe!"}
//                     </Button>
//                   </Form>
//                 </Segment>
//               </Modal.Content>
//             </>
//           }
//         />


//       </Form.Group>
//     </Form>
//   )

// }